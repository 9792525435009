import { Button, Form, Input, message, Select } from "antd";

import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

import { useRecoilState } from "recoil";
import { db } from "../firebase";
import { contentCreatorAudioState } from "../recoil/contentCreatorStates";

export const AudioGen = () => {
  const [audioForm] = Form.useForm();
  const [publicUrl, setPublicUrl] = useState("");
  const [content, setContent] = useRecoilState(contentCreatorAudioState);
  const [audioFiles, setAudioFiles] = useState<any[]>([]);
  const [socketUrl, setSocketUrl] = useState("");
  useEffect(() => {
    getDoc(doc(db, "socket", "sdfgrerretgrgre"))
      .then((snap) => {
        snap.data()?.auth && setSocketUrl(snap.data()?.auth);
      })
      .catch((err) => {
        message.error("Failed to get socket url");
      });
  }, []);
  // useEffect(() => {
  //   const unsubscribe = onSnapshot(
  //     query(
  //       collection(db, "aiAudio"),
  //       orderBy("dateCreated", "desc"),
  //       limitToLast(20)
  //     ),
  //     (snapshot) => {
  //       setAudioFiles(() =>
  //         snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  //       );
  //     }
  //   );
  //   return unsubscribe;
  // }, []);

  const getAudio = () => {
    audioForm.validateFields().then(async (values) => {
      await fetch(`https://api.aimediacommunications.org/gen`, {
        headers: {
          authorization: socketUrl,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "audio",
          data: {
            text: values.prompt,
            fileName: values.name,
            voice: values.voice,
            speed: values.speed,
            platform: values.platform,
          },
        }),
        method: "POST",
      })
        .then(async (res) => {
          await res
            .blob()
            //.json()
            .then(async (res2) => {
              console.log(res2.type);

              //await downloadAudioFile(res2);
              // const blob = new Blob([res2], { type: "audio/mp3" });

              // Create a temporary link element
              const link = document.createElement("a");
              link.href = URL.createObjectURL(res2); // Create an object URL for the Blob
              link.download = `${values.fileName}.mp3`; // Set the file name for the download

              // Append the link to the document, click it, and remove it
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              // Revoke the object URL to free memory
              URL.revokeObjectURL(link.href);
            })
            .catch((err) => {
              console.error({ message: "Fetch fail json", item: err });
            });
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(values);
    });
  };

  // const generateAudio = () => {
  //   audioForm
  //     .validateFields()
  //     .then(async (values) => {
  //       console.log(values);

  //       const req = httpsCallable(functions, "getAIVoice");
  //       const result = await req({
  //         text: values.prompt,
  //         fileName: values.name,
  //         voice: values.voice,
  //         speed: values.speed,
  //         platform: values.platform,
  //       })
  //         .then((res) => {
  //           console.log(res.data);
  //           //setPublicUrl(res.data as string);
  //           message.success("Audio generated");
  //           //downloadAudioFile(res.data as any);
  //         })
  //         .catch((err) => {
  //           message.error("Failed to generate");
  //           console.error(err);
  //           return "Failed to generate";
  //         });
  //       return result;
  //     })
  //     .then((result: any) => {})
  //     .catch((err) => {
  //       message.error("Failed to generate");
  //       console.error(err);
  //     });
  // };

  // const createAudio = () => {
  //   audioForm
  //     .validateFields()
  //     .then((values) => {
  //       const newAudioFileId = nanoid();
  //       setDoc(doc(db, `audioFiles/${newAudioFileId}`), {
  //         dateCreated: new Date().getTime(),
  //         text: values.prompt,
  //         complete: false,
  //         fileName: values.name,
  //       })
  //         .then(() => {
  //           message.success("Saved");
  //         })
  //         .catch((err) => {
  //           message.error("Failed to save");
  //           console.error(err);
  //         });
  //     })
  //     .catch((err) => {
  //       message.error("Failed to save");
  //       console.error(err);
  //     });
  // };

  const downloadAudioFile = async (binary: Buffer) => {
    const blob = new Blob([binary], { type: "audio/mp3" });

    // Create a link element
    const link = document.createElement("a");

    // Create a URL for the Blob and set it as the href attribute of the link
    const url = URL.createObjectURL(blob);
    link.href = url;

    // Set the download attribute with the desired filename
    link.download = `AI Audio - ${new Date().getTime()}.mp3`;

    // Append the link to the document body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const validateFileName = (_: any, value: any) => {
    const invalidChars = /[\\/:*?"<>|" "]/;

    if (!value) {
      return Promise.reject(new Error("File name cannot be empty"));
    }

    if (invalidChars.test(value)) {
      return Promise.reject(new Error("File name contains invalid characters"));
    }

    return Promise.resolve();
  };

  return (
    <div style={{ padding: "1rem", width: "100%", maxWidth: "600px" }}>
      <h1>AudioGen</h1>
      {/* <div>Sorry, this is local only.</div> */}
      <Form
        form={audioForm}
        initialValues={{
          prompt: content && content,
          platform: "ElevenLabs",
          voice: "vnd0afTMgWq4fDRVyDo3",
          speed: 1,
        }}
      >
        <Form.Item
          name="name"
          rules={[{ validator: validateFileName }, { required: true }]}
        >
          <Input placeholder="File name" />
        </Form.Item>
        <Form.Item name="platform" rules={[{ required: true }]}>
          <Select placeholder="Platform">
            <Select.Option value="ElevenLabs">ElevenLabs</Select.Option>
            <Select.Option value="Google">Google Voice</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="voice" rules={[{ required: true }]}>
          <Select placeholder="Select a voice">
            <Select.Option value="en-GB-News-G">
              en-GB-News-G (Female)
            </Select.Option>
            <Select.Option value="en-GB-News-L">
              en-GB-News-L (Male)
            </Select.Option>

            <Select.Option value="vnd0afTMgWq4fDRVyDo3">
              ElevenLabs Debora (Female)
            </Select.Option>
            <Select.Option value="ThT5KcBeYPX3keUQqHPh">
              ElevenLabs Dorothy (Female)
            </Select.Option>
            <Select.Option value="JBFqnCBsd6RMkjVDRZzb">
              ElevenLabs George (Male)
            </Select.Option>
            <Select.Option value="onwK4e9ZLuTAKqWW03F9">
              ElevenLabs Daniel (Male)
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="speed" rules={[{ required: true }]}>
          <Select placeholder="Select a speed">
            <Select.Option value={0.7}>0.7x</Select.Option>
            <Select.Option value={0.8}>0.8x</Select.Option>
            <Select.Option value={0.9}>.9x</Select.Option>
            <Select.Option value={1}>1x</Select.Option>
            <Select.Option value={1.5}>1.5x</Select.Option>
            <Select.Option value={2}>2x</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="prompt" rules={[{ required: true }]}>
          <Input.TextArea
            rows={8}
            placeholder="Text to speech"
            style={{ whiteSpace: "pre-wrap" }}
            onChange={() => {
              setContent(() => audioForm.getFieldValue("prompt"));
            }}
          />
        </Form.Item>
      </Form>
      {content && <div>{content.split(" ").length - 1} words</div>}
      <Button onClick={() => getAudio()}>Generate</Button>
    </div>
  );
};
